import React, { Component } from "react";
import { Link } from "gatsby";
import { Drawer } from "antd";
import Logo from "../../images/weighworks-logo.svg";
import MenuIcon from "../../images/menuicon.svg";

class Headerpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isTop: true,
      pathname: ""
    }
  }

  // Title Update
  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onCloseDrawer = () => {
    this.setState({
      visible: false
    });
  };
  render() {


    return (
      <div>
        <nav className='navbar navbar-expand-lg navbar-light top-navbar fixed-top'>
          <div className="container">
            <div className="main__header d-flex align-items-center">
              <div className="main__header--logo">
                <Link to={`/`}>
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="main__header--left ml-auto">
                <ul className="main__header--links">
                  <li>
                    <a href="https://dash.weighworks.com/login">Login</a>
                  </li>
                  <li>
                    <Link
                      className="btn btn-demo"
                      to={`/signup`}
                    >
                      Request Demo
                  </Link>
                  </li>
                </ul>

                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.showDrawer}
                >
                  <img src={MenuIcon} alt="" />
                </button>

              </div>
            </div>
          </div>
        </nav>

        <Drawer
          className="mobile-drawer"
          placement="right"
          closable={true}
          width="320"
          onClose={this.onCloseDrawer}
          visible={this.state.visible}
        >
          <div>
            <ul className="mobile-custom-links">
              <li>
                <a href="https://dash.weighworks.com/login">Login</a>
              </li>
              <li>
                <Link
                  className="btn btn-demo"
                  to={`/signup`}
                >
                  Request Demo
                  </Link>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default Headerpage;
