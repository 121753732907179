import React, { Component } from "react";
import { Link } from "gatsby";
import moment from "moment";

class Footerpage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <footer className="footer">
        <div className="container top-border">
          <div className="row row-reverse">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <p>© Copyright {moment().format('YYYY')}. Curbside Technologies LLC. All Rights Reserved.</p>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <ul>
                <li>
                  <Link className="btn btn-demo" to={`/legal`}>Legal</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footerpage;
